.container-card{
  display: flex;
  width: 80%;
  margin-bottom: 30px;
  
}
.container-card .right{
  flex: 2; /* Adjust flex value as needed */
  padding-right: 20px;
  padding-left: 10px;
  /* Add additional styling as needed */

}
.container-card .right h1{
  font-size: 42px;
  font-weight: bold;
  padding-right: 20px;
  margin-bottom: 10px;
}
.container-card .right h2{
  font-weight: bold;
  font-size: 24px;
}
.container-card .right .item {
  display: flex;
  align-items: center;
}

.container-card .right .item .icon {
  font-size: 1.7rem;
  margin-left: 10px;
}


.container-card .left{
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-card .left .gallery  {
  --d: 32s; /* duration */
  width:380px;
  object-fit: cover;
  height: 330px;
  display: grid;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, #0000, #000 10% 90%, #0000);
}
@media (max-width: 1025px) {
  .container-card{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

  }
  .container-card .right h1{
    font-size: 40px;
    font-weight: bold;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .container-card .left .gallery {
    height: 350px;
  }
}
@media (max-width: 480px) {
  .container-card .right{
    display: none;
  }
  .container-card{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;

  }
  .container-card .left .gallery {
    width:90%;
    height: 350px;
  }
}
.container-card .left .gallery > img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Change from 'cover' to 'contain' */
  animation: r var(--d) linear infinite;
}

.container-card .left .gallery > img:nth-child(2) {animation-delay: calc(1 * var(--d) / -4)}
.container-card .left .gallery > img:nth-child(3) {animation-delay: calc(2 * var(--d) / -4)}
.container-card .left .gallery > img:nth-child(4) {animation-delay: calc(3 * var(--d) / -4)}


@keyframes r {
  75%    {transform: translate(-300%)}
  75.01% {transform: translate( 100%)}
}

@media (min-width: 1500px) {
  .container-card .right h1{
    font-size: 60px;
    font-weight: bold;
    padding-right: 20px;
    margin-bottom: 10px;
  }
  .container-card .right h2{
    font-weight: bold;
    font-size: 34px;
  }
  .container-card .right .item .icon {
    font-size: 2rem;
    margin-left: 15px;
  }
  .container-card .left .gallery  {
    --d: 32s; /* duration */
    width:440px;
    object-fit: cover;
    height: 370px;
    display: grid;
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, #0000, #000 10% 90%, #0000);
  }
}
