@import "../../app.css";

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    margin-bottom: 70px;

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 70px;
        background-color: #4169E1;
        padding: 50px;
        width: 100%; 
        @include mobile{
          padding-bottom: 25px;
          padding-top: 25px;
          padding-left: 13px;
          padding-right: 13px;
          margin-bottom: 20px;
        } 
        h1{
            font-size: 4rem;
            font-weight: bolder;
            text-align: center;
            color:white;
            @include tablet{
              font-size: 3rem;
              margin-bottom: 0;
              text-align: center;
            }
            @include mobile{
              margin-bottom: 0;
              text-align: center;
              font-weight: bolder;
              color:white;
              font-size: 2.5rem;             
            }
            

        }
        
        }
    

    .container {
      @include mobile{
        display: none;
       }
     // Ensure it takes full width
        max-width: 100%; // To handle cases where parent has padding or border
       // box-sizing: border-box; // Include padding and border in the element's total width and height
      //  margin: 0 auto; // Center it if needed and remove external margins
       // background-image: url("https://www.transparenttextures.com/patterns/clean-gray-paper.png");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        width: 90%;        
        background-color: #F5F5F5;
        margin-bottom: 70px;
        border-radius: 10px;
        @include tablet{
          width: 100%;   
          margin-bottom: 20px;

      } 
        .top {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            margin-top: 15px;
            h2{
                margin: 0;
                font-size: 2.5rem;
                font-weight: 1000;
                color:#4169E1;
            }
        }

        .bottom {
          
           // width: 90%;
            display: flex;
            justify-content: flex-end;
            flex-direction: row-reverse; 
            justify-content: center;
            padding: 0px;
            margin-top: 0; 
           // background-color:red;
            
            // Aligns content to the right
            @include mobile{
              display: flex;
              flex-flow: column;
             .right{order: 1;}    
             .left{order: 2;}    
           } 
            .left {
                // Left section styles (optional)
                flex: 1;
                padding-top: 10px;
                margin-right: 40px;
              //  background-color: aqua;
              @include mobile{
              width: 100%;  
             } 
                .text{
                    width: 400px;
                    //height: 190px;
                    background-color: #f0f0f0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                    margin-top: 10px;
                    background-color: white;
                  
                    p{
                        margin: 0;
                        padding: 20px;
                        font-size: 1.1rem;
                        color: black
                    }


                }
            }

            .right {
                flex: 1;
                display: grid;
                padding: 0;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 10px; /* Space between grid items reduced to 20px */
                padding-top: 10px;
              //  background-color: blueviolet;

              

              @include mobile{
                width: 100%;
                grid-template-columns:1fr;
                grid-gap: 3px;
  
             } 
             @include tablet{
               grid-template-columns: repeat(2, 1fr);
           } 
                .list {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    display: contents;
                    
            
                    .item {
                        width: 190px;
                        height: 190px;
                        background-color: #f0f0f0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                        margin: 10px; /* Adjust the margin if needed */
                        padding: 10px;
                        cursor: pointer;
                        @include mobile{
                          width: 90px;
                          height: 90px;  
                          margin: 3px;
                       } 
                       @include tablet{
                        width: 140px;
                        height: 140px;  
                     } 
                        .icon {
                            font-size: 70px;
                            margin: 30px;
                            @include mobile{
                              font-size: 50px;
                           } 
                           @include tablet{
                            font-size: 30px;
                        }
                        p {
                            font-size: 22px;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
            }
              }
}
/* ... (your existing styles) ... */
}





.recommendations {
    width: 90%;
    background-color: #F5F5F5;
    border-radius: 10px;
    margin-top: 40px;
   // overflow: hidden; /* Hide overflow to prevent scrollbar */
   display: flex;
   flex-direction: column;
   
   .buttons-container{
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    button {
      background: black;
      border: solid 2px black;
      padding: .375em 1.125em;
      font-size: 1rem;
      padding: 10px 20px; // Reduced padding, adjust as needed
      margin: 0; // Adjust margin if needed
      a{
        text-decoration: none;
        color: white;

      }
      
    }
    
   }

.button-arounder {
  font-size: 2rem;
  background: hsl(190deg, 30%, 15%);
  color: hsl(190deg, 10%, 95%);
  
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  
  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  background: #4169E1;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  border: 2px solid #4169E1;
}


  
    .top {
      margin-top: 15px;
      h2 {
        margin: 0;
        font-size: 2.5rem;
        font-weight: 1000;
        color: #4169E1;
        text-align: center;
      }
    }
  
    .slick-slider {
      width: 90%;
      margin: auto;
  
      .slick-list {
        overflow: hidden; /* Hide the overflowing slides */
      }
  
      .slick-track {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        transition: transform 0.5s ease-in-out;
      }

      .slick-prev {
        left: -20px;
        z-index: 20000;
       right: auto; /* Reset the right position */
      }

      .slick-next {
        right: -5px; /* Adjust the distance from the right side */
        z-index: 20000;
        left: auto; /* Reset the left position */
      }

  .slick-prev::before {
    content: '←'; /* Unicode arrow character for left arrow */
    font-size: 40px; /* Set the size of the arrow */
    opacity: 0.75;
    line-height: 1; /* Adjust line-height to ensure visibility */
    color: black;
  }
  
  .slick-next::before {
    content: '→'; /* Unicode arrow character for right arrow */
    font-size: 40px; /* Set the size of the arrow */
    opacity: 0.75;
    line-height: 1; /* Adjust line-height to ensure visibility */
    color: black;
  }
      .carousel-item {
        
       .item{
        background-color: white;
        border-radius: 15px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border:1px dashed #05174e;
        height: 350px;
        width: 90%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        direction: rtl;

        
        .textIcon{
            font-size: 3rem;
            margin: 10px;
            color:black;

        }
        
       }
  
        h2 {
          font-size: 1.2rem;
          font-weight: bold;
          color: black;
          margin-top: auto;
          @include  mobileMini{
            font-size: 1rem;
          }
        }
        h3{
            font-size: 1rem;
            color: black;
            @include  mobileMini{
              font-size: 0.8rem;
            }
        }
        p {
          font-size: 1rem;
          color: black;
          @include  mobileMini{
            font-size: 0.8rem;
          }
        }
      }
    }
 
  
  
    }
  }