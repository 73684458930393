@import "../../app.css";

.about{
   // background-color: #F5F5F5;
    .abouttext{
        background-color: #4169E1;
        padding: 51px;
        @include tablet{
          margin-bottom: 0;
        }
        @include mobile{
          padding-bottom: 25px;
            padding-top: 25px;
            padding-left: 13px;
            padding-right: 13px;
            margin-bottom: 20px;
            } 
        h1{
            text-align: center;
            font-size: 4rem;
            font-weight: bolder;
            color:white;
              @include tablet{
                font-size: 3rem;
                margin-top: 0;
                margin-bottom: 0;
              }
              @include mobile{
                margin-bottom: 0;
                margin-top: 0;
                text-align: center;
                font-weight: bolder;
                color:white;
                font-size: 2.5rem;             
              }
        }
    }
    width: 100%;
    .container{
        background-color: #F5F5F5;
        border-radius: 10px;
        width: 90%;
        display: flex;
        margin-top: 40px;
        margin-bottom: 60px;
      
         @include tablet{
            display: flex;
            flex-flow: column;
           .left{order:2};
           .right{order: 1;}  
          }
          @include mobile{
            display: flex;
            flex-flow: column;
           .left{order:2};
           .right{order: 1;}        
         } 
       
        .right{
            flex:2;
            display: flex;
            flex-direction: column;
            margin-left: 40px;
           @include mobile{
            width: 100%;
              } 
              @include tablet{
                width: 100%;
              }

            .first{
    
                .item{
                    display: flex;
                    align-items: center;
        
        
                    h4{
                        margin-right: 10px;
                        font-weight: bold;
                }
                .icon{
                    font-size: 1.3rem;
                }
            }
            h2{
                font-size: 3rem;
                font-weight: bold;
                @include mobile{
                  
                  font-size: 2.5rem;             
                }
    
            }
    
            }

        .text{
           
            h5{
                margin-top: 10px;
                font-weight: bold;
            }
            p{
                font-size: 1.2rem;
            }
    
        }
            }.left{
              
                flex:1;
                margin-top: 40px;
                @include tablet{
                    margin-top:0;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;
                  }
                  @include mobile{
                    margin-top:0;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;
                }
                img{
                  height: 500px;
                  width: 100%;
                  border-radius: 10px;  
                 
                @include tablet{
                    height: 350px;
                    width: 45%;
                  }
                  @include mobile{
                    height: 350px;
                    width: 80%;
                }
    
                }

            }
}
}