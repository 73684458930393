@import "../../app.css";

.services{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    margin-bottom: 70px;
    @include tablet{
      margin-bottom: 0;
  } 
  @include mobile{
    margin-bottom: 0;
  } 
    .top{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 70px;
        background-color: #4169E1;
        padding: 50px;
        width: 100%; 
        @include tablet{
          margin-bottom: 20px;
      }
      @include mobile{
        padding-bottom: 25px;
        padding-top: 25px;
        padding-left: 13px;
        padding-right: 13px;
        margin-bottom: 20px;
      } 

        h1{
            font-size: 4rem;
            font-weight: bolder;
            color:white;
            @include tablet{
              font-size: 3rem;
              margin-top: 0;
              margin-bottom: 0;
            }
            @include mobile{
              margin-bottom: 0;
              text-align: center;
              font-weight: bolder;
              color:white;
              font-size: 2.5rem;             
            }
           
        }

    }.bottom{
        background-color: #F5F5F5;
        margin-bottom: 70px;
        border-radius: 10px;
        padding: 30px;
        width: 90%;        
        p{
            font-size: 1.2rem;
        }
        h5{
            font-weight: bold;
            font-size: 2rem;
            @include mobile{
              font-size: 1.6rem;
            } 
        }
        @import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);



/*** VARIABLES ***/
/* Colors */
$black: #1d1f20;
$blue: #83e4e2;
$green: #a2ed56;
$yellow: #fddc32;
$white: #fafafa;



/*** EXTEND ***/
/* box-shadow */
%boxshadow {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
}



/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
  right: -1rem; // Position to the right instead of left
  left: auto; // Disable the left positioning

}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: $white;
  color: $black;
  font-family: 'Raleway', sans-serif;
}

main {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 1rem;
}


ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;

  @include mobile{
    padding-left: 0;
  } 
  > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    @extend %boxshadow;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    padding-right: 2.5rem; // Increase right padding
    padding-left: 1rem; // Adjust left padding as needed
    font-size: 1.2rem;
    

    &::before,
    &::after {
      background: linear-gradient(135deg, $blue 0%,$green 100%);
      border-radius: 1rem 1rem 0 1rem;
      content: '';
      height: 3rem;
      left: -1rem;
      overflow: hidden;
      position: absolute;
      top: -1rem;
      width: 3rem;
    }
    &::before {
      align-items: flex-end;
      @extend %boxshadow;
      content: counter(gradient-counter);
      color: $black;
      display: flex;
      font: 900 1.5em/1 'Montserrat';
      justify-content: flex-end;
      padding: 0.125em 0.25em;
      z-index: 1;
      align-items: flex-start; // Align to the start (right side)
      justify-content: flex-start; // Justify content to the start (right side)

    }
    @for $i from 1 through 5 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($green, $i * 0.2) 0%,rgba($yellow, $i * 0.2) 100%);
      }
    }
    @for $i from 6 through 10 {
      &:nth-child(10n+#{$i}):before {
        background: linear-gradient(135deg, rgba($green, 1 - (($i - 5) * 0.2)) 0%,rgba($yellow, 1 - (($i - 5) * 0.2)) 100%);
      }
    }
    + li {
      margin-top: 2rem;
    }
  }
}


    }
}