@import "../../app.css";

.contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
    .top {
        width: 100%; 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        //margin-top: 30px;
        margin-bottom: 70px;
        background-color: #4169E1;
        padding: 50px;
        @include tablet{
            margin-bottom: 0;
          }
        @include mobile{
        padding-bottom: 25px;
          padding-top: 25px;
          padding-left: 13px;
          padding-right: 13px;
          margin-bottom: 20px;
          } 
        h1{
            font-size: 4rem;
            font-weight: bolder;
            color:white;
            @include tablet{
                font-size: 3rem;
                margin-bottom: 0;
                text-align: center;
              }
              @include mobile{
                margin-bottom: 0;
                text-align: center;
                font-weight: bolder;
                color:white;
                font-size: 2.5rem;             
              }

        }
    }.bottom{
        width: 90%;
        border-radius: 10px;
        background-color: #f5f5f5;
        display: flex;
       
         @include tablet{
            width: 95%;
            margin-top: 40px;
           } 
           @include mobile{
            margin-top: 20px;
            display: flex;
            flex-flow: column;
           .right{order: 2;}   
           .middle{order:1}     
         } 
        .right{
            flex: 0.9;
            display: flex;
            flex-direction: column;
            margin-right: 30px;
            @include mobile{
                margin-right: 5px;
            }
            @include tablet{
             margin-left: 20px;  

            } 
            h2{
                margin: 0;
                font-size: 2.5rem;
                font-weight: 1000;
                color:black;
                padding: 15px;
                @include mobile{
                    font-size: 2rem;
                } 
                @include  mobileMini{
                    font-size: 1.7rem;
                }
            }
            a{
                color: inherit;
                text-decoration: none;
                margin: 0;
                font-size: 1.5rem;
                color: black

            }
            p{
                margin: 0;
                margin: 0;
                font-size: 1.5rem;
                color: black

            }
            .icon{
                font-size: 2.2rem;
                color:#7090f1;

            }.location{
                display: flex;
                align-items: center;
                margin: 10px;
            }
            .white-circle{
                background-color: #fff;
                height: 70px;
                width: 70px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px dashed black;
                margin-left: 15px;
            }
        }
        .middle{
            flex: 1;
            background-color: transparent;
            margin-left: 50px;
            @include mobile{
            margin-top: 20px;
            margin-bottom: 35px;
            padding-right: 10px;
           
        } 
            h2{
                margin: 0;
                font-size: 2.5rem;
                font-weight: 1000;
                color:black;
                padding: 15px;
                @include mobile{
                    font-size: 2rem;
                } 
                @include  mobileMini{
                    font-size: 1.7rem;
                }
            }
            input[type="text"],
            textarea {
                width: 100%;
                padding: 10px;
                margin: 10px 0;
                border: 1px solid #ddd;
                border-radius: 15px;
                box-sizing: border-box;
                font-size: 16px;
            }

            textarea.content {
                height: 100px; /* Larger field for content */
            }

            .submit-btn {
                width: 100%;
                padding: 10px;
                border: none;
                background: linear-gradient(#1d1b1b, #4d96f7);
              //  background-color: #4169E1;
                color: white;
                border-radius: 15px;
                cursor: pointer;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .submit-btn:hover {
                background-color: #3658a4;
            }

        }
        .left{
            flex:1;
            display: flex;
            align-items: center;
            @include mobile{
                display: none;   
             }
             @include tablet{
                display: none;   

            } 
        
            img{
                width: 90%;
                height: 80%;
                border-radius: 10px;

            }
}
}
}