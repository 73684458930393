@charset "UTF-8";


.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
.contact .top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  background-color: #4169E1;
  padding: 50px;
}
@media (max-width: 1025px) {
  .contact .top {
    margin-bottom: 0;
  }
}
@media (max-width: 480px) {
  .contact .top {
    padding-bottom: 25px;
    padding-top: 25px;
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 20px;
  }
}
.contact .top h1 {
  font-size: 4rem;
  font-weight: bolder;
  color: white;
}
@media (max-width: 1025px) {
  .contact .top h1 {
    font-size: 3rem;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .contact .top h1 {
    margin-bottom: 0;
    text-align: center;
    font-weight: bolder;
    color: white;
    font-size: 2.3rem;
  }
}
.contact .bottom {
  width: 90%;
  border-radius: 10px;
  background-color: #cce2ec66;
  display: flex;
}
@media (max-width: 1025px) {
  .contact .bottom {
    width: 95%;
    margin-top: 40px;
  }
}
@media (max-width: 480px) {
  .contact .bottom {
    margin-top: 20px;
    display: flex;
    flex-flow: column;
  }
  .contact .bottom .right {
    order: 2;
  }
  .contact .bottom .middle {
    order: 1;
  }
}
.contact .bottom .right {
  flex: 0.9;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}
@media (max-width: 480px) {
  .contact .bottom .right {
    margin-right: 5px;
  }
}
@media (max-width: 1025px) {
  .contact .bottom .right {
    margin-left: 20px;
  }
}
.contact .bottom .right h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 1000;
  padding: 15px;
}
@media (max-width: 480px) {
  .contact .bottom .right h2 {
    font-size: 2rem;
  }
}
@media (max-width: 365px) {
  .contact .bottom .right h2 {
    font-size: 1.7rem;
  }
}
.contact .bottom .right a {
  color: inherit;
  text-decoration: none;
  margin: 0;
  font-size: 1.5rem;
  color: black;
}
.contact .bottom .right p {
  margin: 0;
  margin: 0;
  font-size: 1.5rem;
  color: black;
}
.contact .bottom .right .icon {
  font-size: 2.2rem;
  color: #7090f1;
}
.contact .bottom .right .location {
  display: flex;
  align-items: center;
  margin: 10px;
}
.contact .bottom .right .white-circle {
  background-color: #fff;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed black;
  margin-left: 15px;
}
.contact .bottom .middle {
  flex: 1;
  background-color: transparent;
  margin-left: 50px;
}
@media (max-width: 480px) {
  .contact .bottom .middle {
    margin-top: 20px;
    margin-bottom: 35px;
    padding-right: 10px;
  }
}
.contact .bottom .middle h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 1000;
  padding: 15px;
}
@media (max-width: 480px) {
  .contact .bottom .middle h2 {
    font-size: 2rem;
  }
}
@media (max-width: 365px) {
  .contact .bottom .middle h2 {
    font-size: 1.7rem;
  }
}
.contact .bottom .middle input[type=text],
.contact .bottom .middle textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-sizing: border-box;
  font-size: 16px;
}
.contact .bottom .middle textarea.content {
  height: 100px; /* Larger field for content */
}
.contact .bottom .middle .submit-btn {
  width: 100%;
  padding: 10px;
  border: none;
  background: #4169E1;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.contact .bottom .middle .submit-btn:hover {
  background-color: #3658a4;
}
.contact .bottom .left {
  flex: 1;
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .contact .bottom .left {
    display: none;
  }
}
@media (max-width: 1025px) {
  .contact .bottom .left {
    display: none;
  }
}
.contact .bottom .left img {
  width: 90%;
  height: 80%;
  border-radius: 10px;
}/*# sourceMappingURL=contact.css.map */