@charset "UTF-8";


.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 70px;
}
.home .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;
  background-color: #4169E1;
  padding: 50px;
  width: 100%;
}
@media (max-width: 480px) {
  .home .heading {
    padding-bottom: 25px;
    padding-top: 25px;
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 20px;
  }
}
.home .heading h1 {
  font-size: 4rem;
  font-weight: bolder;
  text-align: center;
  color: white;
}
@media (max-width: 1025px) {
  .home .heading h1 {
    font-size: 3rem;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .home .heading h1 {
    margin-bottom: 0;
    text-align: center;
    font-weight: bolder;
    color: white;
    font-size: 2.3rem;
  }
}


.home .container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  width: 90%;
  background-color: #cce2ec66;
  margin-bottom: 70px;
  margin-top: 50px;
  border-radius: 10px;
  /* ... (your existing styles) ... */
}
@media (max-width: 480px) {
  .home .container {
    display: none;
  }
}
@media (max-width: 1025px) {
  .home .container {
    width: 100%;
    margin-bottom: 20px;
  }
}
.home .container .top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 15px;
}
.home .container .top h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 1000;
  color: #4169E1;
}
.home .container .bottom {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0px;
  margin-top: 0;
}
@media (max-width: 480px) {
  .home .container .bottom {
    display: flex;
    flex-flow: column;
  }
  .home .container .bottom .right {
    order: 1;
  }
  .home .container .bottom .left {
    order: 2;
  }
}
.home .container .bottom .left {
  flex: 1;
  padding-top: 10px;
  margin-right: 40px;
}
@media (max-width: 480px) {
  .home .container .bottom .left {
    width: 100%;
  }
}
.home .container .bottom .left .text {
  width: 400px;
  background-color: #cce2ec66;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  background-color: white;
}
.home .container .bottom .left .text p {
  margin: 0;
  padding: 20px;
  font-size: 1.1rem;
  color: black;
}
.home .container .bottom .right {
  flex: 1;
  display: grid;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px; /* Space between grid items reduced to 20px */
  padding-top: 10px;
}
@media (max-width: 480px) {
  .home .container .bottom .right {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 3px;
  }
}
@media (max-width: 1025px) {
  .home .container .bottom .right {
    grid-template-columns: repeat(2, 1fr);
  }
}
.home .container .bottom .right .list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: contents;
}
.home .container .bottom .right .list .item {
  width: 190px;
  height: 190px;
  background-color: #cce2ec66;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 10px; /* Adjust the margin if needed */
  padding: 10px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .home .container .bottom .right .list .item {
    width: 90px;
    height: 90px;
    margin: 3px;
  }
}
@media (max-width: 1025px) {
  .home .container .bottom .right .list .item {
    width: 140px;
    height: 140px;
  }
}
.home .container .bottom .right .list .item .icon {
  font-size: 70px;
  margin: 30px;
}
@media (max-width: 480px) {
  .home .container .bottom .right .list .item .icon {
    font-size: 50px;
  }
}
@media (max-width: 1025px) {
  .home .container .bottom .right .list .item .icon {
    font-size: 30px;
  }
}
.home .container .bottom .right .list .item .icon p {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}
.home .recommendations {
  width: 90%;
  background-color: #cce2ec66;
  border-radius: 10px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.home .recommendations .buttons-container {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.home .recommendations .buttons-container button {
  background: black;
  border: solid 2px black;
  padding: 0.375em 1.125em;
  font-size: 1rem;
  padding: 10px 20px;
  margin: 0;
}
.home .recommendations .buttons-container button a {
  text-decoration: none;
  color: white;
}
.home .recommendations .button-arounder {
  font-size: 2rem;
  background: hsl(190, 30%, 15%);
  color: hsl(190, 10%, 95%);
  box-shadow: 0 0px 0px hsla(190, 15%, 5%, 0.2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  transition: border-top-left-radius var(--dur) var(--delay) ease-out, border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out, border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out, border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out, box-shadow calc(var(--dur) * 4) ease-out, transform calc(var(--dur) * 4) ease-out, background calc(var(--dur) * 4) steps(4, jump-end);
}
.home .recommendations .button-arounder:hover,
.home .recommendations .button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190, 15%, 5%, 0.2);
  transform: translateY(-4px);
  background: #4169E1;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  border: 2px solid #4169E1;
}
.home .recommendations .top {
  margin-top: 15px;
}
.home .recommendations .top h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 1000;
  color: #4169E1;
  text-align: center;
}
.home .recommendations .slick-slider {
  width: 90%;
  margin: auto;
}
.home .recommendations .slick-slider .slick-list {
  overflow: hidden; /* Hide the overflowing slides */
}
.home .recommendations .slick-slider .slick-track {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: transform 0.5s ease-in-out;
}
.home .recommendations .slick-slider .slick-prev {
  left: -20px;
  z-index: 20000;
  right: auto; /* Reset the right position */
}
.home .recommendations .slick-slider .slick-next {
  right: -5px; /* Adjust the distance from the right side */
  z-index: 20000;
  left: auto; /* Reset the left position */
}
.home .recommendations .slick-slider .slick-prev::before {
  content: "←"; /* Unicode arrow character for left arrow */
  font-size: 40px; /* Set the size of the arrow */
  opacity: 0.75;
  line-height: 1; /* Adjust line-height to ensure visibility */
  color: black;
}
.home .recommendations .slick-slider .slick-next::before {
  content: "→"; /* Unicode arrow character for right arrow */
  font-size: 40px; /* Set the size of the arrow */
  opacity: 0.75;
  line-height: 1; /* Adjust line-height to ensure visibility */
  color: black;
}
.home .recommendations .slick-slider .carousel-item .item {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #05174e;
  height: 350px;
  width: 90%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  direction: rtl;
}
.home .recommendations .slick-slider .carousel-item .item .textIcon {
  font-size: 3rem;
  margin: 10px;
  color: black;
}
.home .recommendations .slick-slider .carousel-item h2 {
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  margin-top: auto;
}
@media (max-width: 365px) {
  .home .recommendations .slick-slider .carousel-item h2 {
    font-size: 1rem;
  }
}
.home .recommendations .slick-slider .carousel-item h3 {
  font-size: 1rem;
  color: black;
}
@media (max-width: 365px) {
  .home .recommendations .slick-slider .carousel-item h3 {
    font-size: 0.8rem;
  }
}
.home .recommendations .slick-slider .carousel-item p {
  font-size: 1rem;
  color: black;
}
@media (max-width: 365px) {
  .home .recommendations .slick-slider .carousel-item p {
    font-size: 0.8rem;
  }
}/*# sourceMappingURL=home.css.map */

.home .ourcustomers{
  width: 90%;
  background-color: #cce2ec66;
  border-radius: 10px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.home .ourcustomers .top h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 1000;
  color: #4169E1;
  text-align: center;
  margin-bottom: 10px;
}
.home .ourcustomers .bottom{
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.home .ourcustomers .bottom img {
  width: 130px;
  height: 130px;
  margin: 10px;

}
@media (max-width: 480px) {
.home .ourcustomers .bottom img  {
  width: 82px;
  height: 78px;
  margin: 3px;

}
}