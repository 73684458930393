@import "../../app.css";

* {
  box-sizing: border-box;
}

.accordion {
  display: none;
  margin-top: 30px;
  width: 90%;
  padding-top: 10px;
  border-radius: 10px;
  background-color: #cce2ec66;
}
  @media (max-width: 1025px) {
    .accordion {
      display: block;
    }
  }

  .accordion html{
    width:100%;
  height: 100%;
  color: rgba(48,69,92,0.8);
  background: #dce7eb;
  overflow-y: scroll;
  }

  .accordion body{
    font-family: 'Titillium Web', sans-serif;
  min-height:0;
  max-width: 450px;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
  margin: 90px 0;
  padding:30px;
  background: #fefffa;
  }

  .accordion h2{
    letter-spacing: 2px;   
    font-size: 1.4rem;
    margin-right: 24px; 
  }

  .accordion h1{
    margin-right: 14px; 
    font-weight: bold;
  }

  .accordion ul{
    list-style: none;
  padding-right:10px;
  margin: 0;
  }

  .accordion ul li{
    position: relative;
    padding-bottom: 4px;
    padding-top: 18px;
    border-top: 1px solid #dce7eb; 
  }

  .accordion ul li input[type="checkbox"]{
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  .accordion ul li i{
    position: absolute;
    transform: translate(-6px,0);
    margin-top: 16px;
    right: 0;
  } 

  .accordion ul li input[type=checkbox]:checked ~ p {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    transform: translateX(-50%);
    
    }

    .accordion ul li input[type="checkbox"]:checked~i::before{
      transform: translate(2px,0) rotate(45deg);
    }
    .accordion ul li input[type="checkbox"]:checked~i::after{
      transform: translate(-2px,0) rotate(-45deg);
    }

    .accordion ul li i::before,ul li i::after{
      content: "";
      position: absolute;
      background: #333;
      width: 3px;
      height: 9px;
    }
    .accordion ul li i::before{
      transform: translate(-2px,0)rotate(45deg);
    }
    .accordion ul li i:after {
    transform: translate(2px, 0) rotate(-45deg);
    }
    .accordion p{
    font-size: 1.3rem;
    color: rgba(48,69,92,0.8);
    line-height: 26px;
    letter-spacing: 1px;
    position: relative;
    padding: 0 11px;
    max-height: 800px;
    margin-top: 14px;
    opacity: 1;
    transform: translate(0,0);
    overflow: hidden;     
    }
    .accordion.transition, p, ul li i:before, ul li i:after {
    transition: all 0.25s ease-in-out;
    
    }
    .accordion .flipIn, h1, ul li {
    animation: flipdown 0.5s ease both;
    }
    .accordion ul li:nth-of-type(1) {
    animation-delay: 0.5s;
    }
    .accordion ul li:nth-of-type(2) {
    animation-delay: 0.75s;
    }
    .accordion ul li:nth-of-type(3) {
    animation-delay: 1s;
    }
    .accordion ul li:last-of-type {
    padding-bottom: 0;
    }







/*
html{
  width:100%;
  height: 100%;
  color: rgba(48,69,92,0.8);
  background: #dce7eb;
  overflow-y: scroll;

  
}
body{
  font-family: 'Titillium Web', sans-serif;
  min-height:0;
  max-width: 450px;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
  margin: 90px 0;
  padding:30px;
  background: #fefffa;
  //box-shadow: 0 7px 7px 7px orange inset;       
}

h2{
  letter-spacing: 2px;   
  font-size: 1.4rem;
  margin-right: 24px; 
}

ul{
  list-style: none;
  padding-right:10px;
  margin: 0;
}
ul li{
  position: relative;
  padding-bottom: 4px;
  padding-top: 18px;
  border-top: 1px solid #dce7eb;  
}
ul li input[type="checkbox"]{
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
ul li i{
  position: absolute;
  transform: translate(-6px,0);
  margin-top: 16px;
  right: 0;
} 
ul li input[type=checkbox]:checked ~ p {
margin-top: 0;
max-height: 0;
opacity: 0;
transform: translateX(-50%);

}

ul li input[type="checkbox"]:checked~i::before{
  transform: translate(2px,0) rotate(45deg);
}
ul li input[type="checkbox"]:checked~i::after{
  transform: translate(-2px,0) rotate(-45deg);
}
ul li i::before,ul li i::after{
  content: "";
  position: absolute;
  background: #333;
  width: 3px;
  height: 9px;
}
ul li i::before{
  transform: translate(-2px,0)rotate(45deg);
}
ul li i:after {
transform: translate(2px, 0) rotate(-45deg);
}
p{
font-size: 1.3rem;
color: rgba(48,69,92,0.8);
line-height: 26px;
letter-spacing: 1px;
position: relative;
padding: 0 11px;
max-height: 800px;
margin-top: 14px;
opacity: 1;
transform: translate(0,0);
overflow: hidden;     
}
.transition, p, ul li i:before, ul li i:after {
transition: all 0.25s ease-in-out;

}
.flipIn, h1, ul li {
animation: flipdown 0.5s ease both;
}
ul li:nth-of-type(1) {
animation-delay: 0.5s;
}
ul li:nth-of-type(2) {
animation-delay: 0.75s;
}
ul li:nth-of-type(3) {
animation-delay: 1s;
}
ul li:last-of-type {
padding-bottom: 0;
}

}
*/
