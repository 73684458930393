
@import url(https://fonts.bunny.net/css?family=amita:700);

.gallery {
    width: 100%;
    justify-content: center;
    align-items: center;

    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
@media (max-width: 480px) {
  .gallery  {
    margin-bottom: 20px;
  }
}
.gallery .top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: #4169E1;
    padding: 50px;
  }
  @media (max-width: 1025px) {
    .gallery .top {
      margin-bottom: 0;
    }
  }
  @media (max-width: 480px) {
    .gallery .top {
      padding-bottom: 25px;
      padding-top: 25px;
      padding-left: 13px;
      padding-right: 13px;
      margin-bottom: 20px;
    }
  }
  .gallery .top h1 {
    font-size: 4rem;
    font-weight: bolder;
    color: white;
  }
  @media (max-width: 1025px) {
    .gallery .top h1 {
      font-size: 3rem;
      margin-bottom: 0;
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    .gallery .top h1 {
      margin-bottom: 0;
      text-align: center;
      font-weight: bolder;
      color: white;
      font-size: 2.3rem;
    }
  }
.gallery .gallery-container img {
    width: 250px;
    height: 250px;
    margin: 10px;
    border: 10px solid #cce2ec66;
    
    transition-duration: 1s;
}

.gallery .gallery-container img:hover {
    border: 5px solid #c1e7f866;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: none;
    filter: none;
}
.gallery .gallery-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
@media (max-width: 480px) {
    .gallery .gallery-container {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .gallery .gallery-container img {
        width: 46%;
        height: 170px;
        margin: 5px;
        border: 7px solid #cce2ec66;
        transition-duration: 1s;
    }
  }