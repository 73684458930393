
.accessibility{
    margin: 15px;
    padding: 10px;
    border: 2px solid black;
    margin-bottom: 20px;
}

ul {
    list-style-type: circle;
  }

  li{
    left: 0;
  }

  .accessibility h5{
    font-weight: bolder;
    margin-top: 10px;
    margin-right: 10px;

  }

  .accessibility p{
    margin: 0;
    margin-right: 10px;
  }
