body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    width: 100%; /* Full width for smaller screens */
 
}

.header, .footer {
    width: 100%;
    /* Other styles... */
}

@mixin mobile{
    @media(max-width:480px){
        @content;
    }
}
@mixin mobileMini{
    @media(max-width:365px){
        @content;
    }
}
@mixin tablet{
    @media(max-width:1025px){
        @content;
    }
}

  